import {
  Collections,
  APIs,
  acceptedLable,
  rejectedLable,
  ENTITY_TYPE,
  unsubscribeList,
  PMS_OWS_REQ,
  OPERA_OWS_PRECHECKIN,
  checkInLable,
  checkOutLable,
} from '../config/constants'
import { db, timestamp } from '../config/firebase'
import { actions } from '../Store'
import Axios from '../utility/axiosHelper'
import { updatationData } from './common'
import { GetAxiosHeaders } from '../config/utils'
import { GetCurrentUser } from './user'
export const ReservationCollectionListener = async (
  hotelId,
  pmsType,
  dispatch
) => {
  try {
    const collectionKey = `ReservationCollectionListener${Collections.PMSRESERVE}`
    if (!hotelId || unsubscribeList[collectionKey]) return
    dispatch(actions.setLoadingReservations(true))
    const sub = db
      .collection(Collections.PMSRESERVE)
      .where('hotelId', '==', hotelId)
      .where('isDelete', '==', false)
      .where('pmsType', '==', pmsType)
      .onSnapshot(
        reservationSnapshot => {
          const reservations = []
          reservationSnapshot.forEach(doc => {
            reservations.push({ id: doc.id, ...doc.data() })
          })
          dispatch(actions.setReservations(reservations))
          dispatch(actions.setLoadingReservations(false))
        },
        error => {
          console.error(error)
          dispatch(actions.setLoadingReservations(false))
        }
      )
    unsubscribeList[collectionKey] = sub
  } catch (error) {
    console.error(error)
    dispatch(actions.setLoadingReservations(false))
  }
}
export const unsubscribeReservations = hotelId => {
  const collectionKey = `ReservationCollectionListener${Collections.PMSRESERVE}`
  if (unsubscribeList[collectionKey]) {
    unsubscribeList[collectionKey]()
    delete unsubscribeList[collectionKey]
  }
}
export const preCheckinRequestCollectionListener = async (
  hotelId,
  pmsType,
  dispatch
) => {
  try {
    const collectionKey = `preCheckinRequestCollectionListener${Collections.PMSPRECHECKIN}`
    if (!hotelId || unsubscribeList[collectionKey]) return
    dispatch(actions.setLoadingPreCheckinRequests(true))
    const sub = db
      .collection(Collections.PMSPRECHECKIN)
      .where('hotelId', '==', hotelId)
      .where('pmsType', '==', pmsType)
      .where('isDelete', '==', false)
      .onSnapshot(
        preCheckinRequestSnapshot => {
          const preCheckinRequest = []
          preCheckinRequestSnapshot.forEach(doc => {
            preCheckinRequest.push({ id: doc.id, ...doc.data() })
          })
          dispatch(actions.setPreCheckinRequets(preCheckinRequest))
          dispatch(actions.setLoadingPreCheckinRequests(false))
        },
        error => {
          console.error(error)
          dispatch(actions.setLoadingPreCheckinRequests(false))
        }
      )
    unsubscribeList[collectionKey] = sub
  } catch (error) {
    console.error(error)
    dispatch(actions.setLoadingPreCheckinRequests(false))
  }
}
export const unsubscribePreCheckinRequests = hotelId => {
  const collectionKey = `preCheckinRequestCollectionListener${Collections.PMSPRECHECKIN}`
  if (unsubscribeList[collectionKey]) {
    unsubscribeList[collectionKey]()
    delete unsubscribeList[collectionKey]
  }
}
function getUniqueTransactionId() {
  let counter = parseInt(localStorage.getItem('transactionCounter')) || 100000
  counter += 1
  localStorage.setItem('transactionCounter', counter)
  return counter.toString().padStart(6, '0')
}
function getCurrentDateTime() {
  const date = new Date()
  // Get the ISO string (e.g., 2024-07-03T14:23:45.123Z)
  const isoString = date.toISOString()
  // Extract the date and time parts
  const dateTime = isoString.slice(0, -1) // Remove the trailing 'Z'
  // Get the timezone offset in minutes and convert to hours and minutes
  const timezoneOffset = -date.getTimezoneOffset()
  const offsetSign = timezoneOffset >= 0 ? '+' : '-'
  const offsetHours = String(
    Math.floor(Math.abs(timezoneOffset) / 60)
  ).padStart(2, '0')
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0')
  // Combine everything
  return `${dateTime}${offsetSign}${offsetHours}:${offsetMinutes}`
}
export const callOperaReservationCollectionSaveListenerApi =
  async hotelInfo => {
    try {
      const operaConfigDomain = hotelInfo?.pmsDomain
      const operaConfigUserName = hotelInfo?.pmsUserName
      const operaConfigPassword = hotelInfo?.pmsPassword
      const uniqueTransactionId = getUniqueTransactionId()
      const currnetDateTime = getCurrentDateTime()
      const soapRequestBody = PMS_OWS_REQ.futureBookingSummary(
        operaConfigDomain,
        operaConfigUserName,
        operaConfigPassword,
        uniqueTransactionId,
        currnetDateTime
      )
      await Axios.post(APIs.FETCH_OPERA_RESERVATIONS, { soapRequestBody })
    } catch (error) {
      console.error(
        'Error calling Opera Reservation Collection Save Listener API:',
        error
      )
    }
  }
export const callOperaCloudReservationCollectionSaveListenerApi = async () => {
  try {
    const hotelId = 'SAND01CN'
    await Axios.post(APIs.RESERVATIONS, { hotelId })
  } catch (error) {
    console.error(
      'Error calling Opera Cloud Reservation Collection Save Listener API:',
      error
    )
  }
}
export const UpdateGuestRoomNumber = async (guestId, roomNumber, dispatch) => {
  try {
    if (!guestId) {
      return { success: false, message: 'Guest ID is required' }
    }
    const guestDocRef = db.collection(Collections.GUEST).doc(guestId)
    const guestDocSnapshot = await guestDocRef.get()
    if (!guestDocSnapshot.exists) {
      return { success: false, message: 'Guest not found' }
    }
    if (guestDocSnapshot.data().roomNumber === roomNumber) {
      return { success: false, message: 'Room number is already updated' }
    }
    await guestDocRef.update({
      roomNumber: roomNumber,
      roomNumberLc: roomNumber,
    })
    const updatedGuestDoc = await guestDocRef.get()
    dispatch(
      actions.setGuest({ id: updatedGuestDoc.id, ...updatedGuestDoc.data() })
    )
    return { success: true, message: 'Room number updated successfully' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}
export const sendEmail = async (reqData, reqId, template) => {
  try {
    const filteredData = reqData.filter(item => item.id === reqId)
    if (filteredData.length === 0) {
      console.log(`No data found for reqId: ${reqId}`)
      return { success: false, message: 'No data found for this request ID' }
    }
    const reciverEmailId = filteredData[0].email
    const name = filteredData[0].name
    const variables = { '%name%': name, '%link%': 'tetsing' }
    let res = await Axios.post(APIs.SEND_PMS_EMAIL, {
      reciverEmailId,
      variables,
      template,
    })
    return res
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}
export const sendEmailByEmailId = async (
  emailId,
  variables,
  template,
  hotelId
) => {
  try {
    let res = await Axios.post(APIs.SEND_PMS_EMAIL, {
      emailId,
      variables,
      template,
    })
    if (res?.statusCode === 200) {
      const reservationCollection = await db
        .collection(Collections.PMSRESERVE)
        .where('hotelId', '==', hotelId)
        .where('isDelete', '==', false)
        .where('email', '==', emailId)
        .get()
      if (reservationCollection.empty) {
        return {
          success: false,
          message: 'No reservation Found with this Email',
        }
      } else {
        reservationCollection.docs.map(doc => {
          const docData = doc.data()
          db.collection(Collections.PMSRESERVE)
            .doc(doc.id)
            .update({ emailAction: 'Sent' })
          return docData
        })
        return { success: true, message: 'Email Sent' }
      }
    } else {
      console.log('Failure:', res.data.message)
      return { success: false, message: res.data.message }
    }
    return res
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}
export const acceptRejectPmsCheckin = async (
  _hotelId,
  guestId,
  accepted,
  email,
  preCheckinRequests,
  hotelInfo
) => {
  try {
    const batch = db.batch()
    let guestRequest = preCheckinRequests.filter(i => i.email === email)
    console.log('guestRequest', guestRequest)
    let checkInGuestByEmail = guestRequest.filter(
      i => i.status === acceptedLable
    )
    if (accepted === false || checkInGuestByEmail.length > 0) {
      const guestRef = db.collection(Collections.PMSPRECHECKIN).doc(guestId)
      batch.update(guestRef, {
        status: accepted ? acceptedLable : rejectedLable,
        checkedInTime: timestamp(),
        checkedOutTime: null,
        ...updatationData(),
      })
      await batch.commit()
      return {
        success: false,
        message: 'Pre-checkin Rejected!! Guest has already been Pre-checked in',
      }
    }
    // to ftech the pmsType form guestRequest taking 0 index cause guestRequest array should have only one collection
    // cause two reqeust cant be rasied with same email
    const getPmsType = guestRequests => {
      if (guestRequests.length > 0) {
        return guestRequests[0].pmsType
      }
      return null
    }
    // Call OPERA OnPremise  pre-checkin API // Similarly we need to add for WINHMS and CLOUD
    if (getPmsType(guestRequest) === 'OPERA OWS') {
      let res
      try {
        const operaConfigDomain = hotelInfo?.pmsDomain
        const operaConfigUserName = hotelInfo?.pmsUserName
        const operaConfigPassword = hotelInfo?.pmsPassword
        const reservationDataByEmail = await db
          .collection(Collections.PMSRESERVE)
          .where('hotelId', '==', _hotelId)
          .where('isDelete', '==', false)
          .where('email', '==', email)
          .get()
        const reservationUniqueIds = reservationDataByEmail.docs.map(
          doc => doc.data().UniqueID
        )
        if (reservationUniqueIds.length === 0) {
          return {
            success: false,
            message: 'Reservation does not exsists anymore, please reject.',
          }
        }
        const soapRequestBody = PMS_OWS_REQ.preCheckin(
          operaConfigDomain,
          operaConfigUserName,
          operaConfigPassword,
          reservationUniqueIds[0]
        )
        res = await Axios.post(APIs.OPERA_OWS_PRECHECKIN, { soapRequestBody })
      } catch (apiError) {
        console.log('OPERA API Error:', apiError)
        return {
          success: false,
          message: 'Failed to call OPERA pre-checkin API',
        }
      }
      const resultStatusFlag =
        res.data?.['soap:Envelope']?.['soap:Body']?.PreCheckinResponse
          ?.Result?.['$']?.resultStatusFlag
      if (resultStatusFlag !== 'SUCCESS') {
        return {
          success: false,
          message: 'OPERA pre-checkin API response was not successful',
        }
      }
    }
    const guestRef = db.collection(Collections.PMSPRECHECKIN).doc(guestId)
    batch.update(guestRef, {
      status: accepted ? acceptedLable : rejectedLable,
      checkedInTime: timestamp(),
      checkedOutTime: null,
      ...updatationData(),
    })
    // Uncomment and update the notification code as needed
    // const notificationCollectionRef = db.collection(Collections.NOTIFICATIONS);
    // const notificationSnapshot = await notificationCollectionRef
    //   .where('senderId', '==', guestId)
    //   .where('receiverId', '==', GetCurrentUser().uid)
    //   .where('entityType', '==', ENTITY_TYPE.GUEST)
    //   .where('notificationType', '==', 'CHECKIN')
    //   .where('readStatus', '==', false)
    //   .get();
    // notificationSnapshot.forEach(n => {
    //   const notificationRef = notificationCollectionRef.doc(n.id);
    //   batch.update(notificationRef, { readStatus: true, ...updatationData() });
    // });
    await batch.commit()
    return { success: true, message: 'Pre-checkin Accepted!!' }
  } catch (error) {
    console.log('Function Error:', error)
    return {
      success: false,
      message: error?.message || 'Something went wrong while updating check-in',
    }
  }
}
export const AcceptRejectPmsRoomAssignCheckIn = async (
  _hotelId,
  guestId,
  accepted,
  roomNumber,
  checkInCheckOutRequests
) => {
  try {
    const batch = db.batch()
    let guestRequest = checkInCheckOutRequests.filter(
      i => i.roomNumberLc === roomNumber
    )
    let checkInGuestByRoomNo = guestRequest.filter(
      i => i.status === checkInLable
    )
    if (accepted && checkInGuestByRoomNo.length > 0) {
      return { success: false, message: 'Room number already Occupied' }
    }
    const guestRef = db.collection(Collections.PMSPRECHECKIN).doc(guestId)
    batch.update(guestRef, {
      status: accepted ? checkInLable : rejectedLable,
      checkedInTime: timestamp(),
      checkedOutTime: null,
      ...updatationData(),
    })
    // change read status of check-in notification for current user
    // if user has not seen notification & accept/reject checkin
    const notificationCollectionRef = db.collection(Collections.NOTIFICATIONS)
    const notificationSnapshot = await notificationCollectionRef
      .where('senderId', '==', guestId)
      .where('receiverId', '==', GetCurrentUser().uid)
      .where('entityType', '==', ENTITY_TYPE.GUEST)
      .where('notificationType', '==', 'CHECKIN')
      .where('readStatus', '==', false)
      .get()
    notificationSnapshot.forEach(n => {
      const notificationRef = notificationCollectionRef.doc(n.id)
      batch.update(notificationRef, { readStatus: true, ...updatationData() })
    })
    await batch.commit()
    return { success: true, message: '' }
  } catch (error) {
    console.log({ error })
    console.log(error)
    return {
      success: false,
      message: error?.message || 'Something went wrong while updating check-in',
    }
  }
}