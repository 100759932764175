import React, { useContext, useEffect, useState } from 'react'
import { Table, Button, message, Modal, Select, Form, Input } from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  AcceptRejectCheckIn,
  AddGuestListener,
} from '../../../../services/guest'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { GetCurrentUser } from '../../../../services/user'
import {
  PaginationOptions,
  pendingLable,
  rejectedLable,
  secondsToShowAlert,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AdminRequest } from '../../../../services/notification'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import { getCommonColumns, sanitiseImages } from '../../../../config/utils'
import { UpdateGuestRoomNumber, sendEmail, AcceptRejectPmsRoomAssignCheckIn } from '../../../../services/pms'
import MenuGallery from '../../Restaurant/MenuGallery'
const GetButtonTextAndClass = row => {
  const disabled = row.status !== pendingLable ? 'disabled' : ''
  let acceptButtonText = 'ACCEPT'
  let rejectButtonText = 'REJECT'
  if (row.status !== pendingLable) {
    acceptButtonText = ''
    rejectButtonText = ''
    if (row.status === rejectedLable) {
      rejectButtonText = 'REJECTED'
    } else {
      acceptButtonText = 'ACCEPTED'
    }
  }
  return { disabled, acceptButtonText, rejectButtonText }
}
const PMSassignrooms = () => {
  const { hotelId, userInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isImageViewClicked, setIsImageViewClicked] = useState(false)
  const [requestId, setRequestId] = useState(false)
  const [requestRoomNumber, setRequestRoomNumber] = useState('')
  const [email, setEmail] = useState('')
  const [requestReferenceId, setReferenceId] = useState('')
  const [accepted, setAccepted] = useState(false)
  const [confirmationTitle, setConfirmationTitle] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [guestId, setGuestId] = useState('')
  const [selectedRoomNumber, setSelectedRoomNumber] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const [filteredCheckingCheckoutRequestData, setFilteredData] = useState([])
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [rdata, setRdata] = useState(null)
  const { Option } = Select
  const { checkInCheckOutRequests, loadingGuests } = useSelector(state => state)
  const preCheckinRequestsData = useSelector(state => state.preCheckinRequests)
  const dispatch = useDispatch()
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  useEffect(() => {
    try {
      const filtered = preCheckinRequestsData.filter(
        item =>
          item.source === 'pms' &&
          (item.status === 'Accepted' || item.status === 'Check In')
      )
      setFilteredData(filtered)
    } catch (err) {
      console.error('Error filtering data:', err)
      setError(err)
    }
  }, [preCheckinRequestsData])
  useEffect(() => {
    AddGuestListener(hotelId, dispatch)
  }, [dispatch, hotelId])
  useEffect(() => {
    if (rdata && rdata.RoomStatus) {
      const roomNumbers = rdata.RoomStatus.map(room => room._RoomNumber)
      setFilteredOptions(roomNumbers)
    }
  }, [rdata])
  const setMessage = (setMessageFunc, messageText) => {
    setMessageFunc(translateTextI18N(messageText ?? ''))
    setTimeout(() => setMessageFunc(''), secondsToShowAlert)
  }
  const acceptRejctClick = async (id, referenceId, action) => {
    setRequestId(id)
    setReferenceId(referenceId)
    setAccepted(action)
  }
  const acceptRejectCheckIn = async () => {
    try {
      if (showLoader) return
      setIsModalVisible(false)
      setShowLoader(true)
      const { success, message: acceptCheckInMessage } =
        await AcceptRejectPmsRoomAssignCheckIn(
          hotelId,
          requestId,
          accepted,
          selectedRoomNumber,
          filteredCheckingCheckoutRequestData
        )
      if (success) {
        let status
        let templateName
        if (accepted === true) {
          templateName = 'ACCEPTED_CHECKIN_STATUS'
          status = 'accepted'
        } else {
          templateName = 'REJECTED_CHECKIN_STATUS'
          status = 'rejected'
        }
        const userid = GetCurrentUser().uid
        let template_variables = { '%status%': status }
        AdminRequest(
          templateName,
          template_variables,
          userid,
          requestId,
          hotelId,
          requestReferenceId,
          null,
          userInfo.departmentId
        )
        setMessage(
          setSuccessMessage,
          `Guest check in ${accepted ? 'accepted successfully' : 'rejected'} `
        )
        const emailTemp = 'assignRoom'
        sendEmail(filteredCheckingCheckoutRequestData, requestId, emailTemp)
      } else {
        setMessage(setErrorMessage, acceptCheckInMessage)
      }
    } catch (error) {
      message.error(error.message || 'Something went wrong! Please try again!')
    } finally {
      setShowLoader(false)
    }
  }
  const {
    guestFullName,
    serialNumberCol,
    bookingReferanceCol,
    roomNumberCol,
    submittedTimeCol,
  } = getCommonColumns({ translateTextI18N })
  bookingReferanceCol.width = 130
  roomNumberCol.width = 100
  guestFullName.width = 170
  const frontDeskColumns = [
    serialNumberCol,
    guestFullName,
    roomNumberCol,
    bookingReferanceCol,
    {
      title: translateTextI18N('Id Verification'),
      dataIndex: 'imageUrl',
      width: 100,
      render: (_, row) => (
        <>
          {row.imageUrl !== undefined && (
            <MenuGallery menu={sanitiseImages([row.imageUrl])} />
          )}
          {row.imageUrl === undefined && (
            <a className='viewlink' rel='noopener noreferrer' disabled={true}>
              {translateTextI18N('View')}
            </a>
          )}
        </>
      ),
    },
    submittedTimeCol,
    {
      title: translateTextI18N('Action'),
      dataIndex: 'Action',
      width: 120,
      render: (_, row) => (
        <Button
          className={`statusBtn completedBtn ml-2`}
          onClick={() => {
            showModal(row.guestId, row.roomNumber)
            acceptRejctClick(row.id, row.bookingReferance, true)
          }}
        >
          {translateTextI18N('Assign Room')}
        </Button>
      ),
    },
    {
      title: translateTextI18N('Status'),
      dataIndex: 'Status',
      className: '',
      width: 200,
      render: (_, row) => {
        const { disabled, acceptButtonText, rejectButtonText } =
          GetButtonTextAndClass(row)
        const { guestId } = row
        return (
          <div>
            <Button
              className={`statusBtn completedBtn ${disabled}`}
              onClick={() => {
                if (!disabled) {
                  if (row.roomNumber !== undefined) {
                    acceptRejctClick(
                      row.id,
                      row.roomNumber,
                      row.email,
                      row.bookingReferance,
                      true,
                      'Accept Guest',
                      `Are you sure you want to accept the guest`
                    )
                  } else {
                    Modal.info({
                      title: 'Room number not assigned',
                    })
                  }
                }
              }}
            >
              {translateTextI18N(acceptButtonText)}
            </Button>
            <Button
              className={`statusBtn rejectBtn ml-2 ${disabled}`}
              onClick={() => {
                if (!disabled) {
                  acceptRejctClick(
                    row.id,
                    row.roomNumber,
                    row.email,
                    row.bookingReferance,
                    false,
                    'Reject Guest',
                    `Are you sure you want to reject this request ?`
                  )
                }
              }}
            >
              {translateTextI18N(rejectButtonText)}
            </Button>
          </div>
        )
      },
    },
  ]
  const showModal = (guestId, roomNumber) => {
    setIsModalVisible(true)
    setSelectedRoomNumber(roomNumber)
    setGuestId(guestId)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleUpdateRoomNumber = () => {
    console.log('selectedRoomNumber', selectedRoomNumber, 'guestId', guestId)
    setIsModalVisible(false)
    if (!selectedRoomNumber || !guestId) {
      console.error('Invalid room number or guest ID')
      return
    }
    UpdateGuestRoomNumber(guestId, selectedRoomNumber)
      .then(response => {
        console.log(response) // Handle success response
      })
      .catch(error => {
        console.error(error) // Handle error
      })
  }
  const handleOnOk = async () => {
    await acceptRejectCheckIn()
    handleUpdateRoomNumber()
  }
  const handleSearch = value => {
    const filtered = rdata.RoomStatus.map(room => room._RoomNumber).filter(
      room => room.toString().includes(value)
    )
    setFilteredOptions(filtered)
  }
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Assign Room'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Assign Room',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={frontDeskColumns}
                      dataSource={filteredCheckingCheckoutRequestData}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      loading={showLoader || loadingGuests}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title='Assign Room Number'
        visible={isModalVisible}
        onOk={handleOnOk}
        onCancel={handleCancel}
      >
        {/* <Select
          value={selectedRoomNumber}
          style={{ width: 120 }}
          onChange={value => setSelectedRoomNumber(value)}
          onSearch={handleSearch}
          filterOption={false}
          showSearch
        >
          {filteredOptions.map((roomNumber, index) => (
            <Option key={index} value={roomNumber}>
              {roomNumber}
            </Option>
          ))}
        </Select> */}
        <Input
          value={selectedRoomNumber}
          style={{ width: 120 }}
          onChange={e => setSelectedRoomNumber(e.target.value)}
        />
      </Modal>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={() => setShowConfirmationDialog(false)}
        onOkClick={acceptRejectCheckIn}
        title={confirmationTitle}
        message={confirmationMessage}
        okButtonText='Confirm'
      />
    </>
  )
}
export default PMSassignrooms