
import React, { useContext, useEffect, useState } from 'react'
import { Table, message, Button } from 'antd'
import {
  ReservationCollectionListener,
  callOperaReservationCollectionSaveListenerApi,
  callOperaCloudReservationCollectionSaveListenerApi,
  unsubscribeReservations,
  sendEmailByEmailId,
} from '../../../../services/pms'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import {
  PaginationOptions,
  pendingLable,
  emailSentLable,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
const GetButtonTextAndClass = row => {
  const disabled = row.emailAction !== pendingLable ? 'disabled' : ''
  let sendEmailText = 'Send Email'
  if (row.emailAction !== pendingLable) {
    sendEmailText = ''
    if (row.emailAction === emailSentLable) {
      sendEmailText = 'Resend Email'
    }
  }
  return { sendEmailText }
}
const PMSreserve = () => {
  const { hotelId } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const reservations = useSelector(state => state.reservations)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [email, setEmail] = useState('')
  const [variables, setVariables] = useState('')
  const [emailTemp, setEmailTemp] = useState('')
  const { hotelInfo } = useSelector(state => state)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchReservations = async () => {
      setShowLoader(true)
      try {
        const pmsType = hotelInfo?.pmsType
        if (pmsType === 'OPERA CLOUD') {
          await callOperaCloudReservationCollectionSaveListenerApi()
        } else if (pmsType === 'OPERA OWS') {
          await callOperaReservationCollectionSaveListenerApi(hotelInfo)
        }
        // Fetching the Reservation data form DB
        await ReservationCollectionListener(hotelId, pmsType, dispatch)
        const selectedAutomaticEmailTime = hotelInfo?.emailTimePeriod
        console.log(selectedAutomaticEmailTime)
        // Automatically send emails for pending reservations after fetching data
        reservations.forEach(async reservation => {
          const checkInDate = new Date(reservation?.checkinDate)
          // Calculate the time difference in hours between now and check-in date
          const now = new Date()
          const timeDifferenceInHours = Math.abs(
            (now - checkInDate) / (1000 * 60 * 60)
          ) // Convert milliseconds to hours
          if (timeDifferenceInHours <= selectedAutomaticEmailTime) {
            if (reservation.emailAction === pendingLable) {
              const variables = {
                '%name%': reservation.guestName,
                '%link%': `http://localhost:3003/prechecking?${reservation.UniqueID}`,
              }
              const emailTemp = 'pre-checkin'
              const res = await sendEmailByEmailId(
                reservation.email,
                variables,
                emailTemp,
                hotelId
              )
              if (res.success) {
                console.log(`Email sent successfully to ${reservation.email}`)
              } else {
                console.error(`Failed to send email to ${reservation.email}`)
              }
            }
          }
        })
      } catch (error) {
        setErrorMessage('Failed to fetch reservations.')
      } finally {
        setShowLoader(false)
      }
    }
    fetchReservations()
    return () => {
      unsubscribeReservations(hotelId)
    }
  }, [hotelId, dispatch])
  const columns = [
    {
      title: translateTextI18N('Reservation Id'),
      dataIndex: 'reservationId',
      render: (text, row) => `${row.UniqueID}`,
      width: 150,
    },
    {
      title: translateTextI18N('Booking Reference'),
      dataIndex: 'bookingReference',
      render: (text, row) => `${row.bookingRefrence}`,
      width: 150,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      render: (text, row) => `${row.guestName}`,
      width: 200,
    },
    {
      title: translateTextI18N('Email'),
      dataIndex: 'email',
      render: (text, row) => `${row.email}`,
      width: 220,
    },
    {
      title: translateTextI18N('Arrival Date'),
      dataIndex: 'checkinDate',
      render: (text, row) => `${row.checkinDate}`,
      width: 150,
    },
    {
      title: translateTextI18N('Status'),
      dataIndex: 'status',
      render: (text, row) => `${row.status}`,
      width: 150,
    },
    {
      title: translateTextI18N('Action'),
      dataIndex: 'emailAction',
      render: (text, row) => `${row.emailAction}`,
      width: 150,
      render: (_, row) => {
        const { disabled, sendEmailText } = GetButtonTextAndClass(row)
        const { email } = row
        const variables = {
          '%name%': row.guestName,
          '%link%': `http://localhost:3003/prechecking?${row.UniqueID}`,
        }
        const emailTemp = 'pre-checkin'
        return (
          <div>
            <Button
              className={`statusBtn completedBtn`}
              onClick={() => {
                // if (!disabled) {
                setEmail(email)
                setVariables(variables)
                setEmailTemp(emailTemp)
                setShowConfirmationDialog(true)
                setShowLoader(true)
                // }
              }}
            >
              {translateTextI18N(sendEmailText)}
            </Button>
          </div>
        )
      },
    },
  ]
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Reservations'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Reservations',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={!!successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={!!errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={reservations}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={() => {
          setShowConfirmationDialog(false)
          setShowLoader(false)
        }}
        onOkClick={async () => {
          setShowConfirmationDialog(false)
          const res = await sendEmailByEmailId(
            email,
            variables,
            emailTemp,
            hotelId
          )
          console.log('res', res)
          if (res.success === true) {
            setSuccessMessage(res.message)
          } else {
            setErrorMessage(res.message)
          }
          setShowLoader(false)
        }}
        title={`Send Pre-checkin Email`}
        message={`Are you sure you want to send pre-checkin email to the guest`}
        okButtonText='Confirm'
      />
    </>
  )
}
export default PMSreserve